import React from 'react';

export default props => (
  <svg {...props} viewBox="0 0 300 300">
    <path
      d="M278.6,42.9c0-23.6-19.2-42.9-42.9-42.9s-42.9,19.2-42.9,42.9c0,15.8,8.6,29.7,21.4,37.1v35.4L150,147.5
      l-64.3-32.1V79.9c12.8-7.4,21.4-21.3,21.4-37.1C107.1,19.2,87.9,0,64.3,0S21.4,19.2,21.4,42.9c0,15.8,8.6,29.7,21.4,37.1v61.9
      l85.7,42.9v35.4c-12.8,7.4-21.4,21.3-21.4,37.1c0,23.6,19.2,42.9,42.9,42.9s42.9-19.2,42.9-42.9c0-15.8-8.6-29.7-21.4-37.1v-35.4
      l85.7-42.9V79.9C269.9,72.5,278.6,58.7,278.6,42.9z M64.3,21.4c11.8,0,21.4,9.6,21.4,21.4s-9.6,21.4-21.4,21.4s-21.4-9.6-21.4-21.4
      S52.5,21.4,64.3,21.4z M150,278.6c-11.8,0-21.4-9.6-21.4-21.4c0-11.8,9.6-21.4,21.4-21.4s21.4,9.6,21.4,21.4
      C171.4,269,161.8,278.6,150,278.6z M235.7,64.3c-11.8,0-21.4-9.6-21.4-21.4s9.6-21.4,21.4-21.4s21.4,9.6,21.4,21.4
      S247.5,64.3,235.7,64.3z"
    />
  </svg>
);
