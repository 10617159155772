import React from 'react';

export default props => (
  <svg {...props} viewBox="0 0 300 300">
    <path
      d="M150,4C67.3,4,0.3,71,0.3,153.7c0,66.1,42.9,122.2,102.4,142c7.5,1.4,10.2-3.2,10.2-7.2c0-3.6-0.1-13-0.2-25.5
      c-41.6,9-50.4-20.1-50.4-20.1c-6.8-17.3-16.6-21.9-16.6-21.9c-13.6-9.3,1-9.1,1-9.1c15,1.1,22.9,15.4,22.9,15.4
      c13.4,22.9,35,16.3,43.6,12.4c1.4-9.7,5.2-16.3,9.5-20c-33.2-3.8-68.2-16.6-68.2-74c0-16.3,5.8-29.7,15.4-40.2
      c-1.5-3.8-6.7-19,1.5-39.6c0,0,12.6-4,41.2,15.3c11.9-3.3,24.7-5,37.5-5c12.7,0.1,25.5,1.7,37.5,5c28.6-19.4,41.1-15.3,41.1-15.3
      c8.2,20.6,3,35.8,1.5,39.6c9.6,10.5,15.4,23.8,15.4,40.2c0,57.5-35,70.2-68.4,73.9c5.4,4.6,10.2,13.8,10.2,27.7
      c0,20-0.2,36.2-0.2,41.1c0,4,2.7,8.7,10.3,7.2c59.4-19.8,102.3-75.9,102.3-142C299.7,71,232.7,4,150,4z"
    />
  </svg>
);
