import React from 'react';

export default props => (
  <svg {...props} viewBox="0 0 28 28" preserveAspectRatio="none">
    <path d="M 13 0.1875 C 5.925781 0.1875 0.1875 5.925781 0.1875 13 C 0.1875
     20.074219 5.925781 25.8125 13 25.8125 C 20.074219 25.8125 25.8125 20.074219 25.8125
     13 C 25.8125 5.925781 20.074219 0.1875 13 0.1875 Z M 11.9375 3.25 C 11.785156 3.464844
     11.6875 3.71875 11.6875 4 C 11.6875 4.734375 12.265625 5.3125 13 5.3125 C 13.734375 5.3125
     14.3125 4.734375 14.3125 4 C 14.3125 3.71875 14.214844 3.464844 14.0625 3.25 C 18.625 3.746094
     22.253906 7.375 22.75 11.9375 C 22.535156 11.785156 22.28125 11.6875 22 11.6875 C 21.265625
     11.6875 20.6875 12.265625 20.6875 13 C 20.6875 13.734375 21.265625 14.3125 22 14.3125 C
     22.28125 14.3125 22.535156 14.214844 22.75 14.0625 C 22.253906 18.625 18.625 22.253906
     14.0625 22.75 C 14.214844 22.535156 14.3125 22.28125 14.3125 22 C 14.3125 21.265625 13.734375
     20.6875 13 20.6875 C 12.265625 20.6875 11.6875 21.265625 11.6875 22 C 11.6875 22.28125
     11.785156 22.535156 11.9375 22.75 C 7.375 22.253906 3.746094 18.625 3.25 14.0625 C
     3.464844 14.214844 3.71875 14.3125 4 14.3125 C 4.734375 14.3125 5.3125 13.734375 5.3125
     13 C 5.3125 12.265625 4.734375 11.6875 4 11.6875 C 3.71875 11.6875 3.464844 11.785156
     3.25 11.9375 C 3.746094 7.375 7.375 3.746094 11.9375 3.25 Z M 18.3125 7.6875 L 11.375
     11.375 L 7.6875 18.3125 L 14.625 14.625 Z M 13 11.75 C 13.695313 11.75 14.25 12.304688
     14.25 13 C 14.25 13.695313 13.695313 14.25 13 14.25 C 12.304688 14.25 11.75 13.695313
     11.75 13 C 11.75 12.304688 12.304688 11.75 13 11.75 Z "
    />
  </svg>
);
